export state = ->
	top: 0
	show: false

export mutations =
	setTop: (state, top) ->
		state.top = top

	setShow: (state, show) ->
		state.show = show
