
import Modal from '../variation'
import throttle from 'lodash/throttle'
import Mp4Player from './mp4-player'
import getVideo from '~/queries/get-video.gql'
import { kebabCase, getYoutubeVideoID, setupWindowResizeHandler, getYoutubeVideoPlaylistID } from '~/services/helpers'

export default

	components: {
		Modal
		Mp4Player
	}

	data: ->
		player: null
		mounted: false

	# if we have a video on the url, open modal on first render
	fetch: ->
		return unless id = @$route.query.internalVideo
		{ entry: video } = await @$craft.execute
			query: getVideo
			variables:
				id: id

		return unless video

		@$store.commit 'videoModal/play', {
			video: video.videoDesktop?[0] || video.videoMobile?[0],
			title: video?.title,
			subtitles: video?.subtitles
			videoLibraryId: id,
		}


	computed:
		# The mp4 video
		mp4Video: -> @$store.state.videoModal.mp4Video

		# The youtube video url
		youtubeVideoUrl: -> @$store.state.videoModal.youtubeVideoUrl

		# Youtube video id
		videoId: -> getYoutubeVideoID @youtubeVideoUrl if @youtubeVideoUrl

		libraryVideoId: -> @$store.state.videoModal.libraryVideoId

		# Whether the modal should be open
		open: -> !!@$store.state.videoModal.shouldPlay and ( !!@videoId || !!@mp4Video)

		# The playslist id from the youtube video
		playlistId: -> getYoutubeVideoPlaylistID( @youtubeVideoUrl) if @youtubeVideoUrl

		# The youtube embed video url
		embedUrl: ->
			return unless @videoId
			if !@playlistId then return 	"https://www.youtube.com/embed/#{@videoId}?enablejsapi=1&origin=#{process.env.URL}&autoplay=1"
			else return "https://www.youtube.com/embed/#{@videoId}?enablejsapi=1&origin=#{process.env.URL}&autoplay=1&list=#{@playlistId}"

	methods:

		# Clean up
		handleDestroy: ->
			@$store.commit 'videoModal/stop'

		#  Close modal
		close: ->
			@$store.commit 'videoModal/stop'
			@handleDestroy()

