import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=32c222f0&scoped=true&lang=pug"
import script from "./mobile.vue?vue&type=script&lang=coffee"
export * from "./mobile.vue?vue&type=script&lang=coffee"
import style0 from "./mobile.vue?vue&type=style&index=0&id=32c222f0&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c222f0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuroLogo: require('/opt/build/repo/site/components/globals/nuro-logo.vue').default,LayoutHeaderHamburguer: require('/opt/build/repo/site/components/layout/header/hamburguer.vue').default,LayoutHeaderMenu: require('/opt/build/repo/site/components/layout/header/menu.vue').default})
