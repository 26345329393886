###
Notification bar data
###

export state = ->
	data: null
	height: 0
	ready: false
	cookieBoxClosed: false

export mutations =

	# Set data
	setData: (state, data) -> state.data = data

	# Set the notification bar height
	setHeight: (state, height) ->
		state.height = height
		state.ready = true unless state.ready

	setCookieBoxClosed: (state, newVal) -> state.cookieBoxClosed = newVal
