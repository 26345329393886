import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=0805acd8&scoped=true&lang=pug"
import script from "./menu.vue?vue&type=script&lang=coffee"
export * from "./menu.vue?vue&type=script&lang=coffee"
import style0 from "./menu.vue?vue&type=style&index=0&id=0805acd8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0805acd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderHamburguer: require('/opt/build/repo/site/components/layout/header/hamburguer.vue').default,NuroLogo: require('/opt/build/repo/site/components/globals/nuro-logo.vue').default,TextLink: require('/opt/build/repo/site/components/globals/text-link.vue').default,News: require('/opt/build/repo/site/components/globals/news.vue').default})
