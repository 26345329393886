
import Share from './share'
import ClickOutside from 'vue-click-outside'
import VideoMenu from './menu'
import PlaybackSpeedMenu from './playback-speed-menu'
import VolumeControl from './volume-control'
import FullScreenHandler from '~/services/full-screen'
import ProgressBar from './progress-bar'
import CcMenu from './cc-menu'
import {hasVideoGotAudio} from '~/services/check-if-video-has-audio'
import keepFocus from '~/mixins/keep-focus'
import {formatTime} from '~/services/helpers'

###
		# TODO
			- Fix scrubbing (flickering): 30 min
###

export default
	props:
		title: String
		video: Object
		videoSubtitles: Array
		videoId: String

	components: {
		VideoMenu,
		PlaybackSpeedMenu,
		VolumeControl,
		ProgressBar,
		Share
		CcMenu
	}

	mixins: [keepFocus]

	directives: { ClickOutside }

	data: ->
		progress: 0 # Progress of the video
		currentTime: 0 # The current time of the video
		duration: 0 # The video duration
		paused: true # Is the video paused
		volume: 1 # video volume (volume in range 0-1)
		selectedSubtitle: null # If there's an active subtitle track
		isFullScreen: false # if we are in full screen
		isCCMenuOpen: false # if the cc menu is open
		isMenuOpen: false # if general menu is open
		isPlaybackSpeedMenuOpen: false # if playback speed menu is open
		isVolumeMenuOpen: false # if the volume menu is open
		fullScreenHandler: null # The object that handles cross browser compatible full screen api calls
		isScrubbing: false # Whether user is scrubbing the video
		isHover: false # Whether user is hovering over the video
		isNavigatingBack: false # Keeping track if the user has just navigated back from playback speed menu
		isShareOpen: false # Whether the share window is open
		hasAudio: null # Whether the video has audio
		selectedSubtitleIndex: -1 # The index of the currently selected subtitle
		forceOverlayClose: false
		forceOverlayOpen: false


	watch:
		# Update volume of the video
		volume: (volume) ->
			return unless @$refs.video
			@$refs.video.volume = volume

		video:
			immediate: true
			handler: (video) ->
				@addInternalVideoQueryToLocation()

		# Seek time if scrubbing
		progress:
			immediate: true
			handler: (progress) ->
				return unless video = @$refs.video
				if !@isScrubbing then return
				video.currentTime = progress * video.duration
				@currentTime = @$refs.video.currentTime

		isCCMenuOpen:
			immediate: true
			handler: (open) -> if open then @removeFocus()
		isMenuOpen:
			immediate: true
			handler: (open) -> if open then @removeFocus()
		isPlaybackSpeedMenuOpen:
			immediate: true
			handler: (open) -> if open then @removeFocus()
		isVolumeMenuOpen:
			immediate: true
			handler: (open) -> if open then @removeFocus()
		isShareOpen:
			immediate: true
			handler: (open) -> if open then @removeFocus()

		overlayVisible:
			immediate: true
			handler: (open) ->
				if open then @initFocus(() =>
					@forceOverlayClose = true
				)
				else @removeFocus()


	mounted: ->
		@fullScreenHandler = new FullScreenHandler()

		# Get video duration info, and check for audio once metadata has loaded
		@$nextTick =>
			return unless @$refs.video

			@$refs.video.onloadedmetadata = =>
				@duration = @$refs.video.duration || 0
				# Check whether video has audio tracks
				@hasAudio = await hasVideoGotAudio(@video.url)

			window.addEventListener('keydown', @handleKeyDown)


	beforeDestroy: ->
		@removeInternalVideoQueryFromLocation()
		window.removeEventListener 'keydown', @handleKeyDown


	computed:
		# Should we show the controls overlay
		overlayVisible: -> ( @paused || @isHover || @isMenuOpen || @isPlaybackSpeedMenuOpen ) && !@isShareOpen && !@forceOverlayClose

		# The play/pause icon
		stateIcon: -> if @paused then 'icon-play1' else 'icon-pause'

		# The formated video duration
		videoDuration: -> formatTime @duration

		# The formated current time
		videoCurrentTime: -> formatTime @currentTime

		# The icon for the volume  button
		volumeIcon: -> if @volume != 0 then 'icon-speaker' else 'icon-speaker-mute'

		# The icon for the closed captions/cc
		ccIcon: ->
			if !@videoSubtitles?.length then return "icon-cc-disabled"
			if @selectedSubtitle then return "icon-cc-on"
			return "icon-cc-off"

		# The icon for the full screen/exit full screen
		fullScreenIcon: ->
			if @isFullScreen then return "icon-exit-full-screen" else "icon-full-screen"

	methods:
		handleStartScrub: ->
			@isScrubbing = true

		handleStopScrub: ->
			@isScrubbing = false

		addInternalVideoQueryToLocation: ->
			urlSearchParams = new URLSearchParams(this.$route.query);
			currentVideo = urlSearchParams.get('internalVideo')
			if !!currentVideo then return

			urlSearchParams.append('internalVideo', @videoId)
			history.pushState(
				{},
				null,
				@$route.path + '?' + urlSearchParams.toString()
			)

		removeInternalVideoQueryFromLocation: ->
			urlSearchParams = new URLSearchParams(this.$route.query);
			urlSearchParams.delete('internalVideo')

			history.pushState(
				{},
				null,
				@$route.path + '?' + urlSearchParams.toString()
			)

		handleKeyDown: (e) ->
			if ( e.key == "Space" || e.keyCode == 32 ) and !( ['button', 'input'].includes e.target.tagName.toLowerCase() ) then @togglePlayState()

		# Keep track of video progress
		onTimeUpdate: (event) ->
			video = event.target
			duration = 	video.duration
			@currentTime = video.currentTime

			# Only update progress if not scrubbing, to avoid infinite loops
			if !@isScrubbing
				@progress = if !duration then 0 else video.currentTime/video.duration

		# Play/pause video
		togglePlayState: ->
			return unless @$refs.video
			if @$refs.video.paused then @$refs.video.play()
			else @$refs.video.pause()

		# Update volume
		setVideoVolume: (volume) -> @$refs.video.volume = volume

		# Keep track of full screen state
		onFullScreenChange: ->
			@isFullScreen = @fullScreenHandler.getFullScreenElement()

		# Toggle full screen
		toggleFullScreen: ->
			return unless @$refs.video
			if (window.innerWidth < 768) then @fullScreenHandler.toggleFullScreen(@$refs.video)
			else @fullScreenHandler.toggleFullScreen(@$refs.scrollLockElement)

		# Set playback speed
		onPlaybackSpeedChange: (speed) ->
			return unless @$refs.video
			@$refs.video.playbackRate = speed
			@isMenuOpen = false
			@isPlaybackSpeedMenuOpen = false

		# Set play state
		setPlay: -> @paused = false
		setPause: -> @paused = true

		# Handle progress update
		onProgressUpdate: (progress) ->
			return unless @isScrubbing
			@progress = progress

		# show/hide menu
		toggleMenu: -> @isMenuOpen = !@isMenuOpen

		# Open the share modal
		onMenuShare: -> @openShareModal()

		# Open the playback speed menu
		onMenuPlaybackSpeed: ->
			@isPlaybackSpeedMenuOpen = true
			@isMenuOpen = false

		# Toggle picture in picture
		onMenuPictureInPicture: ->
			return unless @$refs.video
			@isMenuOpen = false
			if document.pictureInPictureElement then document.exitPictureInPicture()
			else if document.pictureInPictureEnabled then @$refs.video.requestPictureInPicture()

		# Promptly pause video
		pauseVideo: ->
			return unless @$refs.video
			@$refs.video.pause()

		onVideoClick: (e) ->
			if Array.from(e.target.classList).includes('control-overlay') then @togglePlayState()

		# Keep track if we're hovering over the video
		onMouseEnter: -> @isHover = true
		onMouseLeave: -> @isHover = false

		# Hide menus
		hideMenu: ->
			return unless @isMenuOpen
			@isMenuOpen = false

		hidePlaybackSpeedMenu: ->
			return unless @isPlaybackSpeedMenuOpen
			@isPlaybackSpeedMenuOpen = false

		hideAllMenus: ->
			return unless !@isNavigatingBack
			@hideMenu()
			@hidePlaybackSpeedMenu()

		# Navigate back
		handlePlaybackSpeedMenuBack: ->
			# Flag that we are navigating back, and we shouldn't close the menu window
			@isNavigatingBack = true
			# Close the playback speed menu
			@isPlaybackSpeedMenuOpen = false
			# Keep the menu open
			@isMenuOpen = true

			# Allow click outside to do its thing again
			@$wait 300, => @isNavigatingBack = false

		# Open the share modal
		openShareModal: -> @isShareOpen = true

		# Close the share modal
		closeShareModal: ->
			@isMenuOpen = false
			@isPlaybackSpeedMenuOpen = false
			@isShareOpen = false
			@isCCMenuOpen = false

		toggleCCMenu: -> @isCCMenuOpen = !@isCCMenuOpen

		hideCCMenu: -> @isCCMenuOpen = false

		seek: (progress) ->
			return unless video = @$refs.video
			duration = video.duration
			video.currentTime = progress * duration

		# Set subtitle track
		onSetSubtitle: (index) ->
			tracks = @$refs.video.textTracks

			@selectedSubtitleIndex = index

			if index < 0
				for i in [0..tracks.length-1]
					track = tracks[i]
					if track then track.mode = 'hidden'


			else
				return unless @videoSubtitles?[index] and @$refs.video
				label = @videoSubtitles[index].languageLabel

				for i in [0..tracks.length-1]
					track = tracks[i]
					if track.label == label
						track.mode = 'showing'
					else
						track.mode = 'hidden'

			@hideCCMenu()

