import { render, staticRenderFns } from "./news.vue?vue&type=template&id=037fd000&scoped=true&lang=pug"
import script from "./news.vue?vue&type=script&lang=coffee"
export * from "./news.vue?vue&type=script&lang=coffee"
import style0 from "./news.vue?vue&type=style&index=0&id=037fd000&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "037fd000",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/opt/build/repo/site/node_modules/vue-ssr-carousel/index.js').default,CarouselPagination: require('/opt/build/repo/site/components/globals/carousel-pagination.vue').default})
