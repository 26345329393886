import { render, staticRenderFns } from "./default.vue?vue&type=template&id=4e1e282d&scoped=true&lang=pug"
import script from "./default.vue?vue&type=script&lang=coffee"
export * from "./default.vue?vue&type=script&lang=coffee"
import style0 from "./default.vue?vue&type=style&index=0&id=4e1e282d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e1e282d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderDesktop: require('/opt/build/repo/site/components/layout/header/desktop.vue').default,LayoutHeaderMobile: require('/opt/build/repo/site/components/layout/header/mobile.vue').default,ModalVideo: require('/opt/build/repo/site/components/globals/modal/video/index.vue').default,ModalImage: require('/opt/build/repo/site/components/globals/modal/image.vue').default,LayoutBackToTop: require('/opt/build/repo/site/components/layout/back-to-top.vue').default,LayoutNotificationBarContainer: require('/opt/build/repo/site/components/layout/notification-bar/container.vue').default,LayoutFooter: require('/opt/build/repo/site/components/layout/footer/footer.vue').default})
