
import {v4 as uuidv4 } from 'uuid'

export default
	props:
		width:
			type: Number
		height:
			type: Number
		path:
			type: String
		borderWidth:
			type: Number
		borderColor:
			type: String
		backgroundColor:
			type: String
		id:
			type: String
			default: uuidv4()

