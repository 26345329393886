###
A Video Modal that plays youtube videos
###

export state = ->

	# Is modal open
	shouldPlay: false

	# The Youtube video url
	youtubeVideoUrl: null

	# The mp4 video
	mp4Video: null

	# the id of the library video
	libraryVideoId: null


export mutations =
	# Set the video url
	loadUrl: (state, url) ->
		return unless url
		state.youtubeVideoUrl = url

	play: (state, video) ->
		if typeof video == 'string'
			state.youtubeVideoUrl = video

		else
			state.mp4Video = video

		state.shouldPlay = true
		state.libraryVideoId = video.libraryVideoId

	stop: (state) ->
		state.shouldPlay = false
		state.youtubeVideoUrl = null
		state.mp4Video = null
