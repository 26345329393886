import axios from 'axios'
import getGlobals from '/queries/globals.gql'
import { execute } from '@bkwld/cloak/services/craft'

# Global data
export state = ->

export mutations =

	# Replace the whole state
	set: (state, data) -> Object.assign state, data, hydrated: true

export actions =

	# Fetch global data
	fetch: ({ commit } = { }) ->

		# Collect data
		data = await execute query: getGlobals

		if commit then commit 'set', data
		else return data
