import { render, staticRenderFns } from "./image.vue?vue&type=template&id=26ff7c6c&scoped=true&lang=pug"
import script from "./image.vue?vue&type=script&lang=coffee"
export * from "./image.vue?vue&type=script&lang=coffee"
import style0 from "./image.vue?vue&type=style&index=0&id=26ff7c6c&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26ff7c6c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,Modal: require('/opt/build/repo/site/components/globals/modal/modal.vue').default})
