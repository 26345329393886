import {getSuperEllipsePathAsDataUri, Preset} from "superellipsejs";

export function getMaskStyle(bounds, props) {
		// console.log('bounds are', bounds)
		props = props || {}
    const w = bounds.width;
    const h = bounds.height;
		// const r1 = props?.r1 || Preset.iOS.r1
		// const r2 = props?.r2 || Preset.iOS.r2
		// const p1 = props?.p1
		// const p2 = props?.p2
    const {r1 = Preset.iOS.r1, r2 = Preset.iOS.r2, p1, p2} = props;
		// console.log('r1 is', r1, 'r2 is', r2, 'p1 is', p1, 'p2 is', p2)
    const {dataUri} = getSuperEllipsePathAsDataUri(w, h, p1 !== undefined ? p1 : r1 * Math.min(w, h), p2 !== undefined ? p2 : r2 * Math.min(w, h));
		console.log('data URI is', dataUri)
    return {
        maskImage: `url("${dataUri}")`,
        maskPosition: 'center',
        maskRepeat: 'no-repeat',
        // maskSize: 'contain',
        WebkitMaskImage: `url("${dataUri}")`,
        WebkitMaskPosition: 'center',
        WebkitMaskRepeat: 'no-repeat',
        // WebkitMaskSize: 'contain'
    };
}

// export {Preset}
