
import keepFocus from '~/mixins/keep-focus'
export default
	mixins: [keepFocus]

	props:
		subtitles: Array
		selectedSubtitleIndex: Number

	methods:
		setSubtitle: (val) ->
			@$emit 'set-subtitle', val

	mounted: ->
		@$nextTick => @initFocus(() => @$emit 'close')

