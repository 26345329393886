
import breakpoints from '/assets/vars/breakpoints'
import Card from './news/card'

export default
	props:
		news: Array
		# Layout compact has image next to text on mobile, and only displays first entry on mobile
		# Layout wide has image above text on desktop and mobile and is used in the news block
		# Layout header has image next to text, and only displays first entry on mobile
		layout:
			type: String
			default: "Wide"
			validator: (val) -> val in ["Wide", "Compact", "Header"]

	components: { Card }

	data: ->
	 	# Wheter its mobile or desktop
		viewport: null
		active: 0

	computed:
		# Make responsive image based on columns
		sizes: ->
			mobile = '(max-width: 500px) 100vw'
			desktop = '(min-width: 1440px) 1440px'
			"#{desktop}, #{mobile}, (max-width: 767px) 50vw, 33vw"

		classes: -> [
			@layoutClass
			@widthClass
		]

		layoutClass: -> switch @layout
			when "Compact" then "compact"
			when "Wide" then "wide"
			when "Header" then "header"

		widthClass: -> switch @layout
			when "Compact" then "max-w-medium"
			when "Wide" then "max-w"
			when "Header" then 'max-w-wide'

		aspect: -> switch @layout
			when "Compact" then 295/150
			when "Wide" then 355/292
			when "Header" then 1

		# If images are child of a component that is outside the main "scroller" div
		# the craft-visual lazy loading will not trigger
		shouldLazyLoad: -> @layout == "Wide"

	# Add listeners
	mounted: ->
		@matchMedia = window.matchMedia "(max-width: #{breakpoints.tablet})"
		@handleViewport @matchMedia
		@matchMedia.addListener @handleViewport

	# Cleanup
	beforeDestroy: ->
		@matchMedia.removeListener @handleViewport

	methods:
		onChange: ({index}) -> @active = index

	 	# Detect viewport size
		handleViewport: (event) ->
			@viewport = if event.matches then "mobile" else "desktop"

		# Parse date
		parseDate: (date) ->
			obj = new Date date
			obj.toLocaleString "en-US", {
				day: "numeric"
				month: "long"
				year: "numeric"
			}

		parseDateCompact: (date) ->
			obj = new Date date
			obj.toLocaleString "es-pa", { day: "numeric", month: "numeric", year: "numeric"}
				.replace(/\//g, '.')
				# .replaceAll '/', '.'

		# Check if image is gif
		isGif: (image) ->
			if image && image.length then image[0].mimeType == 'image/gif'

		# Make gif url
		getGif: (image) ->
			imgixUrl = process.env.IMGIX_URL
			if imgixUrl then "#{imgixUrl}/#{image[0].path}"
			else image[0].url

		goTo: (index) ->
			return unless carousel = @$refs.carousel
			carousel.goto index

