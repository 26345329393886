
import NetlifyForm from 'vue-netlify-form'
export default
	components: { NetlifyForm }

	data: ->
		# The form data
		form:
			Email: ''

		textFieldFocus: false

