###
Modal data
###

export state = ->
	open: false

export mutations =

	# Set data
	setOpen: (state, data) -> state.open = data
