
import dimensions from '~/assets/vars/dimensions.json'
import breakpoints from '~/assets/vars/breakpoints.json'

export default

	methods:
		backToTop: ->	@$scroller.scrollTo @top

	computed:
		top: -> @$store.state.backToTop.top
		show: -> @$store.state.backToTop.show

