
import inputMixin from './input-mixin'
export default

	mixins: [ inputMixin ]

	props:

		# Type of textfield
		type:
			type: String
			default: 'text'

		preventSubmitOnEnter:
			type: Boolean
			default: false

		placeholder:
			type: String

	methods:
		keyDown: (event) ->
			if @preventSubmitOnEnter and event.key == 'Enter'
				event.preventDefault()
				return

