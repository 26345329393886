
import { padNum } from '~/services/helpers'
export default

	props:
		active:
			type: Number
			required: true
		length:
			type: Number
			required: true
		hidePage:
			type: Boolean
			defualt: false


	mounted: ->
		window.addEventListener 'resize', @getPageOffset

	computed:
		pageOffset: -> "margin-left: #{@offsetPage}px"

		activePage: -> padNum @active + 1

		content: -> Array.from({@length})

	methods:
		getPageOffset: ->
			return unless ref = @$refs.dot?[@active]
			@offsetPage = ref.offsetLeft

		goTo: (i) -> @$emit 'goTo', i

