###
A Video Modal that plays youtube videos
###

export state = ->

	# Is modal open
	open: false

	# The desktop image
	desktopImage: null

	# The mobile image
	mobileImage: null

export mutations =
	setState: (state, newState) ->
		state.open = newState?.open
		state.desktopImage = newState?.desktopImage
		state.mobileImage = newState?.mobileImage

	setOpen: (state, open) -> state.open = open

	setImages: (state, {desktopImage, mobileImage}) ->
		state.desktopImage = desktopImage
		state.mobileImage = mobileImage

