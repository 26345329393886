
import Vue from 'vue'

# Mapping of Craft _typename to component
export mapping =
	blocks_anchor_BlockType: 'blocks-anchor'
	blocks_accordion_BlockType: 'blocks-accordion'
	blocks_assetAndFeatures_BlockType: 'blocks-asset-and-features'
	blocks_bioCards_BlockType: 'blocks-bio-cards'
	blocks_cardGrid_BlockType: 'blocks-card-grid'
	blocks_careersListing_BlockType: 'blocks-careers-listing'
	blocks_careersListingV2_BlockType: 'blocks-careers-listing-v2'
	blocks_carouselMarquee_BlockType: 'blocks-carousel-marquee'
	blocks_copy_BlockType: 'blocks-copy'
	blocks_copyNuro3_BlockType: 'blocks-copy-nuro-3'
	blocks_copyAndCardGrid_BlockType: 'blocks-copy-and-card-grid'
	blocks_copyAndCtaOverMediaAsset_BlockType: 'blocks-copy-and-cta-over-media-asset'
	blocks_copyCarousel_BlockType: 'blocks-copy-carousel'
	blocks_cta_BlockType: 'blocks-cta'
	blocks_downloads_BlockType: 'blocks-downloads'
	blocks_elaborateCards_BlockType: 'blocks-elaborate-cards'
	blocks_fancyCards_BlockType: 'blocks-fancy-cards'
	blocks_faqListing_BlockType: 'blocks-faq-listing'
	blocks_faqSelections_BlockType: 'blocks-faq-selections'
	blocks_featuresAccordion_BlockType: 'blocks-features-accordion'
	blocks_fiftyFifty_BlockType: 'blocks-fifty-fifty'
	blocks_fleetCarousel_BlockType: 'blocks-fleet-carousel'
	blocks_form_BlockType: 'blocks-form'
	blocks_heroSlider_BlockType: 'blocks-hero-slider'
	blocks_iconList_BlockType: 'blocks-icon-list'
	blocks_logoGarden_BlockType: 'blocks-logo-garden'
	blocks_mediaAsset_BlockType: 'blocks-media-asset'
	blocks_multiSlideMarquee_BlockType: 'blocks-multi-slide-marquee'
	blocks_news_BlockType: 'blocks-news'
	blocks_nextCards_BlockType: 'blocks-next-cards'
	blocks_openRoles_BlockType: 'blocks-open-roles'
	blocks_pressCards_BlockType: 'blocks-press-cards'
	blocks_pressListing_BlockType: 'blocks-press-listing'
	blocks_r2MouseTracker_BlockType: 'blocks-r2-mouse-tracker'
	blocks_revealFrameAnimation_BlockType: 'blocks-reveal-frame-animation'
	blocks_simpleMarquee_BlockType: 'blocks-simple-marquee'
	blocks_simpleCards_BlockType: 'blocks-simple-cards'
	blocks_simpleAccordion_BlockType: 'blocks-simple-accordion'
	blocks_spacer_BlockType: 'blocks-spacer'
	blocks_splitFeature_BlockType: 'blocks-split-feature'
	blocks_splitFeatureAffixed_BlockType: 'blocks-split-feature-affixed'
	blocks_splitText_BlockType: 'blocks-split-text'
	blocks_stats_BlockType: 'blocks-stats'
	blocks_standardMarquee_BlockType: 'blocks-standard-marquee'
	blocks_timeline_BlockType: 'blocks-timeline'
	blocks_textOverImage_BlockType: 'blocks-text-over-image'
	blocks_textRow_BlockType: 'blocks-text-row'
	blocks_verticalLine_BlockType: 'blocks-vertical-line'
	blocks_videoCarousel_BlockType: 'blocks-video-carousel'
	blocks_videoCarouselTwo_BlockType: 'blocks-video-carousel-two'
	blocks_videoCarouselThree_BlockType: 'blocks-video-carousel-three'
	blocks_videoLibrary_BlockType: 'blocks-video-library'
	blocks_wrapper_BlockType: 'blocks-wrapper'
	blocks_heroMarquee_BlockType: 'blocks-hero-marquee'

# Hard import marquee components so they don't get chunked
import '~/components/blocks/multi-slide-marquee/multi-slide-marquee'
import '~/components/blocks/simple-marquee'
import '~/components/blocks/standard-marquee'
import '~/components/blocks/carousel-marquee'
import '~/components/blocks/news'
import '~/components/blocks/spacer'

# Component def for this block
export default
	name: 'BlockList'

	props:
		blocks: Array

		# Used by Wrapper so the children don't get an offset of zero and disable
		# lazly loading. Note, this isn't perfect because any blocks after the
		# wrapper block won't currently be offset by the preceeding wrapper's
		# children count, but it works for our simple need of enabling lazy loading.
		indexOffset:
			type: Number
			default: 0

	computed:

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			(@blocks || [])
			.map (block) -> {
					...block
					componentName: mapping[block.__typename]
				}
			.filter (block) -> !!block.componentName

