
import breakpoints from '/assets/vars/breakpoints.json'
import { getMaskStyle } from '/services/superellipse'
import { getSvgPath } from '/services/squircle'
import Background from './background'
export default
	props:
		cornerRadius:
			type: Number | Object
			default: -> {desktop: 32, mobile: 16}
		cornerSmoothing:
			type: Number
			default: 0.8
		preserveSmoothing:
			type: Boolean
		backgroundColor:
			type: String
			default: "transparent"
		borderWidth:
			type: Number
		borderColor:
			type: String
		forceUpdate:
			default: 0
		clipOverflow:
			type: Boolean
		element:
			type: String
			default: "div"
		enabled:
			type: Boolean
			default: false

	components: { Background }

	data: ->
		width: null
		height: null

	computed:
		styles: ->
			return @nonSquircleStyles unless @clipOverflow and @enabled
			clipPath: "path(\"#{@path}\")"
			"-webkit-cli-path": "path(\"#{@path}\")"

		nonSquircleStyles: ->
			borderRadius: "#{ @radius }px"
			background: @backgroundColor

		path: ->  getSvgPath({@width, @height, cornerRadius: @radius, @cornerSmoothing, @preserveSmoothing})

		desktop: -> parseInt breakpoints.desktop

		mobile: -> parseInt breakpoints['mobile-sm']

		viewportWidth: -> @$store.state.layout.viewport.width

		radius: ->
			return @cornerRadius if typeof @cornerRadius == 'number'
			if @viewportWidth >= @desktop then return @cornerRadius.desktop
			if @viewportWidth <= @mobile then return @cornerRadius.mobile
			@getRadius()


	watch: forceUpdate: ->
		return unless window?
		@getDimensions()


	mounted: ->
		@getDimensions()
		window.addEventListener 'resize', @getDimensions
		@$emit 'hook:mounted'

		@observer = new ResizeObserver @getDimensions
		@observer.observe @$el

	beforeDestroy: ->
		window.removeEventListener 'resize', @getDimensions
		@observer?.disconnect?()

	methods:
		getDimensions: ->
			squircle = @$refs.squircle
			@width = squircle.clientWidth
			@height = squircle.clientHeight

		getRadius: ->
			a = [@mobile, @cornerRadius.mobile]
			b = [@desktop, @cornerRadius.desktop]
			slope = (b[1] - a[1]) / (b[0] - a[0])
			intercept = a[1] - slope * a[0]
			slope * @viewportWidth + intercept

