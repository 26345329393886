
import tinycolor from 'tinycolor2'
export default

	props: block: Object

	data: ->
		backgroundColor: @block.slides[0]?.backgroundColor
		textColor: 'black'

	computed:

		# Map the component name to the slide
		slides: -> @block.slides.map (slide) -> {
			...slide
			componentName: switch slide.__typename
				when 'blocks_r2RevealSlide_BlockType'
					'blocks-multi-slide-marquee-r2-reveal-slide'
				when 'blocks_imageSlide_BlockType'
					'blocks-multi-slide-marquee-image-slide'
				when 'blocks_videoSlide_BlockType'
					'blocks-multi-slide-marquee-video-slide'
		}

		# Set root styles
		styles: ->
			background: @backgroundColor
			color: @textColor

	methods:

		# Set the mix of the background color depending on the progress of the
		# background reveal
		onBackgroundReveal: ({ progress, id }) ->
			activeIndex = @slides.findIndex (slide) -> slide.id == id
			return unless activeIndex > 0

			# Get the current and previous colors
			active = @slides[activeIndex].backgroundColor || 'white'
			previous = @slides[activeIndex - 1].backgroundColor || 'white'

			# Make background color
			@backgroundColor = tinycolor.mix previous, active, progress * 100

			# Figure out what the text color should be
			@textColor = tinycolor.mix @makeTextColor(previous),
				@makeTextColor(active),
				progress * 100

		# Get the text color given the background color
		makeTextColor: (color) ->
			if tinycolor(color).isDark() then 'white' else 'black'

