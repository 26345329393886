
import breakpoints from '/assets/vars/breakpoints'

export default
	props:
		item: Object
		layout: String
		viewport: String
		shouldLazyLoad: Boolean

	computed:
		# Make responsive image based on columns
		sizes: ->
			mobile = '(max-width: 500px) 100vw'
			desktop = '(min-width: 1440px) 1440px'
			"#{desktop}, #{mobile}, (max-width: 767px) 50vw, 33vw"

		aspect: -> switch @layout
			when "Compact" then 295/150
			when "Wide" then 355/292
			when "Header" then 1

		computedAspect: ->
			if @layout == "Compact" and @viewport == "mobile" then 1
			else @aspect

		aspectStyle: -> "aspect-ratio: #{@computedAspect}"

	methods:
		# Parse date
		parseDate: (date) ->
			obj = new Date date
			obj.toLocaleString "en-US", {
				day: "numeric"
				month: "long"
				year: "numeric"
			}

		parseDateCompact: (date) ->
			obj = new Date date
			obj.toLocaleString "es-pa", { day: "numeric", month: "numeric", year: "numeric"}
				.replace(/\//g, '.')
				# .replaceAll '/', '.'

		# Check if image is gif
		isGif: (image) ->
			if image && image.length then image[0].mimeType == 'image/gif'

		# Make gif url
		getGif: (image) ->
			imgixUrl = process.env.IMGIX_URL
			if imgixUrl then "#{imgixUrl}/#{image[0].path}"
			else image[0].url

