
export default

	props:

		# The color of the arc
		color:
			type: String
			default: 'white'

		# The background of the arc, like the color of the block that preceeds
		# this in the case of it's use in a wrapper block
		background: String

		# The start rule of the animation
		# https://greensock.com/docs/v3/Plugins/ScrollTrigger
		start: String | Number

		# The target of the scroll trigger
		trigger: String | Object

		# Don't actually expand
		static: Boolean

	mounted: -> @motionSetup() unless @static
	destroyed: -> @cleanupMounted?()

	methods:

		# Setup scrolltrigger animations
		motionSetup: -> @cleanupMounted = @$setupReducedMotion =>

			# Figure out the trigger, which supports `parent` helper
			trigger = switch @trigger
				when 'parent' then @$el.parentNode
				when undefined then @$el
				else @trigger

			# Expand the arc.  Not using scale because it blurred the SVG in Safari.
			# I eyeballed these values. Needed to preset the width height so it
			# didn't capture pixel values that wouldn't update on resize when at the
			# start trigger
			@$gsap.set @$refs.arc,
				left: '0'
				height: '100%'
				width: '100%'
			@$gsap.to @$refs.arc,
				height: '400%'
				width: '200%'
				left: '-50%'
				ease: 'none'
				scrollTrigger:
					trigger: trigger
					start: @start

