import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=7797f119&scoped=true&lang=pug"
import script from "./footer.vue?vue&type=script&lang=coffee"
export * from "./footer.vue?vue&type=script&lang=coffee"
import style0 from "./footer.vue?vue&type=style&index=0&id=7797f119&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7797f119",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutFooterPrimaryLinks: require('/opt/build/repo/site/components/layout/footer/primary-links.vue').default,LayoutFooterNewsletter: require('/opt/build/repo/site/components/layout/footer/newsletter.vue').default,NuroLogo: require('/opt/build/repo/site/components/globals/nuro-logo.vue').default,LayoutFooterSecondaryLinks: require('/opt/build/repo/site/components/layout/footer/secondary-links.vue').default,LayoutFooterCopyright: require('/opt/build/repo/site/components/layout/footer/copyright.vue').default})
