
import keepFocus from '~/mixins/keep-focus'
export default
	mixins: [keepFocus]

	methods:
		back: -> @$emit 'back'

		playbackSpeed: (val) ->
			@$emit 'playback-speed', val

	data: ->
		speeds: [
			{label: "0.25", value: .25}
			{label: "0.5", value: .5}
			{label: "0.75", value: .75}
			{label: "Normal", value: 1}
			{label: "1.25", value: 1.25}
			{label: "1.5", value: 1.5}
			{label: "1.75", value: 1.75}
			{label: "2", value: 2}
		]

	mounted: -> @$nextTick => @initFocus(() => @$emit 'back')

