
export default

	props: block: Object

	# Wait until the page is transitioned and for notification bar to open before
	# pinning elements because the fixed position is not tweened otherwise
	mounted: -> @$waitForPageTransition @motionSetup

	# Cleanup animations
	destroyed: ->
		@cleanupMounted?()
		@cleanupBackground?()

	computed:

		# Root styles
		styles: -> color: @block.textColor || 'white'

		layout: -> @block.layout || "arched"

		arched: -> @layout == 'arched'

		classes: -> [if @arched then 'arched']

	methods:

		# Setup scrolltrigger animations
		motionSetup: -> @cleanupMounted = @$setupReducedMotion =>

			# Pin some elements
			@$ScrollTrigger.create
				trigger: @$el
				pin: @$refs.pinned
				pinSpacing: false
				start: 'top top'

		# Scale the background image
		onBackground: -> @cleanupBackground = @$setupReducedMotion =>
			@$gsap.to @$refs.background.$el,
				scale: 1.2
				scrollTrigger:
					trigger: @$el
					start: 'top top'

