
import breakpoints from '/assets/vars/breakpoints.json'
import { interpolate } from '/services/helpers'
export default

	props:

		type:
			type: String
			default: 'button'

		size:
			type: String
			validator: (val) -> val in ['small']

		# Hide the arrow, which is added by default
		noArrow:
			type: Boolean
			default: true

		# Render as a circle
		circle: Boolean

		# States
		loading: Boolean
		disabled: Boolean

		# Translate animation on hover
		preventHoverTranslate: Boolean

		# Background color
		color:
			type: String
			validator: (val) -> val in ['purple', 'white', 'charcoal']
			default: 'purple'

	computed:

		# Root classes
		classes: -> [
			"size-#{@size}" if @size
			loading: @loading
			circle: @circle
			"no-arrow" if @noArrow
			"translate-on-hover" if !@preventHoverTranslate
			"color-#{@color}"
		]

		# States that should supress clicks
		nonInteractive: -> @disabled or @loading

		viewportWidth: -> @$store.state.layout.viewport.width

		cornerRadius: -> if @viewportWidth > parseInt breakpoints['mobile-sm'] then 20 else 16

	methods:

		# Prevent clicking if non-interactive
		onClick: (e) ->
			if @nonInteractive
			then e.preventDefault()
			else @$emit 'click'

