# Deps
import timing from '~/assets/vars/timing.json'
import memoize from 'lodash/memoize'
import throttle from 'lodash/throttle'

# Setup Responsive Motion: Handle setup/cleanup of JS animations on component mount & destroy if media query matches, as well as when the query matches or unmatches.
# Use cases: Disable GSAP animations for mobile.  Disable GSAP animations if prefers-reduced-motion.
# Params: motionSetup, motionCleanup: Your setup and cleanup methods.
# Usage:
#   mounted: -> @cleanupResponsiveMotion = @$setupResponsiveMotion @motionSetup, @motionCleanup
#   destroyed: -> @cleanupResponsiveMotion()
export setupResponsiveMotion = (motionSetup, motionCleanup, mediaQueryArg='(true)') ->
	# Handle some common media queries
	if mediaQueryArg == 'reduced-motion'
		mediaQueryString = '(prefers-reduced-motion: no-preference)'
	else if mediaQueryArg == 'tablet-up'
		mediaQueryString = "(min-width: #{breakpoints.tablet})"
	else if mediaQueryArg == 'tablet-down'
		mediaQueryString = "(max-width: #{breakpoints.tablet - 1})"
	else
		mediaQueryString = mediaQueryArg
	mediaQuery = window?.matchMedia mediaQueryString
	mqListener = (mq) -> if mq.matches then motionSetup() else motionCleanup?()
	mediaQuery.addListener mqListener
	# Bind listeners. This will also run motionSetup at mount, if allowed
	mqListener mediaQuery
	# Return cleanup function
	return () ->
		mediaQuery.removeListener mqListener
		motionCleanup?()

export setupReducedMotion = (motionSetup, motionCleanup) ->
	setupResponsiveMotion motionSetup, motionCleanup, 'reduced-motion'


# Visibility Change: Helper that calls your handler functions when the page is hidden (tabbed away) and visible again.
# Usage:
#   mounted: -> @cleanupVisiblityChange = @onVisibilityChange @handleHidden, @handleVisible
#   destroyed: -> @cleanupVisiblityChange()
export onVisibilityChange = (handleHidden, handleVisible) ->
	# Set the name of the hidden property and the change event for visibility
	if (typeof document.hidden != "undefined")
		# Opera 12.10 and Firefox 18 and later support
		hidden = "hidden"
		visibilityChange = "visibilitychange"
	else if (typeof document.msHidden != "undefined")
		hidden = "msHidden"
		visibilityChange = "msvisibilitychange"
	else if (typeof document.webkitHidden != "undefined")
		hidden = "webkitHidden"
		visibilityChange = "webkitvisibilitychange"

	# Handler function
	handleVisibilityChange = () -> if document[hidden] then handleHidden() else handleVisible()

	# Bind the handler function
	document?.addEventListener visibilityChange, handleVisibilityChange, false

	# Return a function that unbinds events.  You should call this when is component is destroyed.
	return () ->
		# Unbind events
		document?.removeEventListener visibilityChange, handleVisibilityChange
		# Call handleHidden() to cleanup remaining setTimeouts/setIntervals
		handleHidden()

# Wait for page transition before doing someting.  If this is the first time
# this is called, also wait for the notification bar delay so that applying
# pinning happens after the page is pushed down.
hasWaitedForPageTransition = false
export waitForPageTransition = (callback) ->
	duration = timing['page-transition-duration'] +
		timing['notification-bar-duration']
	unless hasWaitedForPageTransition
	then duration += timing['notification-bar-delay']
	setTimeout callback, duration
	hasWaitedForPageTransition = true

# Create a script tag and watch for it to load
export loadScript = memoize (src, {
	defer = false
	async = false
} = {}) -> return new Promise (resolve, reject) ->
	script = document.createElement('script')
	script.onload = resolve
	script.onerror = reject
	script.src = src
	script.defer = true if defer
	script.async = true if async
	document.head.appendChild script

# Setup Window Resize Handler
#
# Setup a throttled function that runs when the window is resized.  Pass in an optional media query to run only in a certain media query range.
#
# Params:
#   resizeHandler: Your resize handler (function)
#   throttleTime: Amount to throttle your handler (milliseconds, optional)

export setupWindowResizeHandler = (resizeHandler, throttleTime=300) ->
	throttledResizeHandler = throttle ->
		resizeHandler()
	, throttleTime

	motionCleanup = -> window?.removeEventListener 'resize', throttledResizeHandler

	window?.addEventListener 'resize', throttledResizeHandler

	# Return cleanup function
	return () -> motionCleanup()


# Sort object alphabetically by key values
export sortObjectKeys = (unordered) ->
	Object.keys(unordered)
		.sort()
		.reduce( (obj, key) =>
			obj[key] = unordered[key]
			return obj
		,
		{}
	);

# Extract inner text from tags in a html string, and optionally
# add spaces to separate text within different tags
# export extractInnerTextFromHTMLString = (str, withSpace) ->
# 	return unless process.client
# 	span = document.createElement('span')
# 	span.innerHTML = str
# 	if withSpace
# 		children = span.querySelectorAll('*')
# 		for i in [i..children.length-1]
# 			if children[i].textContent
# 				children[i].textContent+= ' '
# 			else
# 				children[i].innerText+= ' '

# 	[span.textContent || span.innerText].toString().replace(/ + /g, ' ')

export extractInnerTextFromHTMLString = (html) ->
	return unless process.client
	temporaryDivElement = document.createElement('div')
	temporaryDivElement.innerHTML = html
	# temporaryDivElement.textContext || temporaryDivElement.innerText || ""
	temporaryDivElement.innerText || ""

###
https://gist.github.com/takien/4077195
Get youtube video ID from a video URL
supported url formats:
'http://youtube.googleapis.com/v/4e_kz79tjb8?version=3';
'https://www.youtube.com/watch?feature=g-vrec&v=Y1xs_xPb46M';
'http://www.youtube.com/watch?feature=player_embedded&v=Ab25nviakcw#';
'http://youtu.be/Ab25nviakcw';
'http://www.youtube.com/watch?v=Ab25nviakcw';
'<iframe width="420" height="315" src="http://www.youtube.com/embed/Ab25nviakcw" frameborder="0" allowfullscreen></iframe>';
'<object width="420" height="315"><param name="movie" value="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US"></param><param name="allowFullScreen" value="true"></param><param name="allowscriptaccess" value="always"></param><embed src="http://www.youtube-nocookie.com/v/Ab25nviakcw?version=3&amp;hl=en_US" type="application/x-shockwave-flash" width="420" height="315" allowscriptaccess="always" allowfullscreen="true"></embed></object>';
'http://i1.ytimg.com/vi/Ab25nviakcw/default.jpg';
'https://www.youtube.com/watch?v=BGL22PTIOAM&feature=g-all-xit';
'BGL22PTIOAM';
###
export getYoutubeVideoID = (url) ->
	if url.match(/^(https?:\/\/)?(www\.)?(youtube(-nocookie)?\.com\/(v\/|embed\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) then return RegExp.$6

export getYoutubeVideoPlaylistID = (url) ->
  # Regular expression to match the playlist ID in the URL
  match = url.match(/(?:list=)([a-zA-Z0-9_-]+)/)

  # Check if there is a match and return the playlist ID
  if match
    return match[1]
  else
    return null

export makeYoutubeEmbedCodeFromURL = (url) ->
	return unless url
	youtubeVideoID = getYoutubeVideoID url
	"""
	<iframe width="560" height="315" src="https://www.youtube.com/embed/#{youtubeVideoID}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	"""

# Convert to kebab case with optional prefix.  Good for generating a CSS class name from a variable.
# Examples:
#  kebabCase('Image Left')  >>> 'image-left'
#  kebabCase('Image Right', 'layout')  >>> 'layout-image-right'
export kebabCase = (str, prefix='') ->
	return '' unless typeof str == 'string'
	kebab = str
		.toLowerCase() # To lowercase
		.replace(/\s+/g, '-') # Replace spaces with hyphens
		.replace(/-{2,}/g, '-') # Replace multiple consecutive hyphens with a single hyphen
	return kebab unless prefix
	"#{prefix}-#{kebab}"

# Pad a number with 0s
export padNum = (num, size = 2) -> String(Math.round(num)).padStart(size, '0')

###
	Creates a biderectional map given a regular javascript object of type {string: string}
###
export class BiderectionalMask
	constructor: (@forwardMap) ->
		@backwardMap = {}

		Object.entries(@forwardMap).forEach ([ key, value ]) =>
			if !@backwardMap[value] then @backwardMap[value] = []
			@backwardMap[value].push key

	forward: (value) -> @forwardMap[value] || value

	backward: (value) -> @backwardMap[value] || value

	forwardThenBackward: (value) -> @forwardMap[value] || @backwardMap[value] || value

	backwardThenForward: (value) -> @backwardMap[value] || @forwardMap[value] || value

# Interpolate between max an min values
export interpolate = (max, min, bottom, top, x) ->
	return max if x >= top
	return min if x <= bottom
	slope = (max - min) / (top - bottom)
	offset = max - (slope * top)
	( slope * x ) + offset

export getVideoDuration = (videoUrl) ->
	return unless videoUrl

	video = document.createElement('video')
	video.src = videoUrl
	video.load()

	await new Promise (resolve, reject) ->
		video.addEventListener 'loadedmetadata', () ->
			resolve()

	duration = video.duration

	video.src = ''
	video.load()

	return duration


# Input is seconds. This formats the value to hh:mm:ss. Omits hh if video length is < 1h
export formatTime = (time) ->
	hours = Math.floor(time / 3600)
	minutes = Math.floor((time % 3600) / 60)
	seconds = Math.floor(time % 60)
	result = "#{hours.toString().padStart(2, '0')}:#{minutes.toString().padStart(2, '0')}:#{seconds.toString().padStart(2, '0')}"

	if result.startsWith('00:') then return result.substring(3)
