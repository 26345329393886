import { render, staticRenderFns } from "./back-to-top.vue?vue&type=template&id=c588e974&scoped=true&lang=pug"
import script from "./back-to-top.vue?vue&type=script&lang=coffee"
export * from "./back-to-top.vue?vue&type=script&lang=coffee"
import style0 from "./back-to-top.vue?vue&type=style&index=0&id=c588e974&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c588e974",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Btn: require('/opt/build/repo/site/components/globals/btn/btn.vue').default})
