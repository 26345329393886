import throttle from 'lodash/throttle'
import breakpoints from '/assets/vars/breakpoints.json'

# Squircle radius value
r1Desktop = 0.0545
r2Desktop = 0.1482
r1Mobile = 0.0204
r2Mobile = 0.0583

# settings
desktop = parseInt breakpoints.desktop
mobile = parseInt breakpoints['mobile-sm']

# variables related to the general layout of the page
export state = ->

	userAgent: ''
	viewport:
		width: null
		height: null
		clientHeight: null
		screenHeight: null
	squircle:
		r1: null
		r2: null
	headerBackgroundColor: null
	isAssetMarquee: null

export mutations =
	setViewportDimensions: (state, dimensions) -> state.viewport = dimensions
	setSquircleRadius: (state, radius) -> state.squircle = radius
	setHeaderBackgroundColor: (state, color) -> state.headerBackgroundColor = color
	setIsAssetMarquee: (state, isAssetMarquee) -> state.isAssetMarquee = isAssetMarquee
	setUserAgent: (state, userAgent) -> state.userAgent = userAgent

# Shared method for adding listeners to update the store
export createListeners = (store) ->
	# Store the throttle window size

	updateDimensions = ->
		width = window.innerWidth
		height = window.innerHeight
		clientHeight = window.document.documentElement.clientHeight
		screenHeight = screen.height
		store.commit 'layout/setViewportDimensions', {width, height, clientHeight, screenHeight}
		store.commit 'layout/setSquircleRadius',
			r1: interpolate r1Desktop, r1Mobile, mobile, desktop, width
			r2: interpolate r2Desktop, r2Mobile, mobile, desktop, width


	onResize = (callback, throttleMs = 100) ->
		throttledCallback = throttle callback, throttleMs
		window.addEventListener 'resize', throttledCallback
		callback()
		return () -> window.removeEventListener 'resize', throttledCallback

	# Add initial listenerss
	onResize updateDimensions

	# Return the resize helper
	return { onResize }

interpolate = (max, min, bottom, top, x) ->
	return max if x >= top
	return min if x <= bottom
	range = top - bottom
	ratio = (x - bottom) / range
	max * ratio * min * (1 - ratio)

