# OneTrust cookie library
# https://app.onetrust.com

import extend from '../services/extend-vue-app'

export default ({ app }) -> extend app,
  mounted: onMounted

onMounted = ->

	# On mount, set initial state based on whether 'OptanonAlertBoxClosed' cookie exists
	cookieBoxClosed = doesClosedCookieExist()
	@$store.commit 'notification-bar/setCookieBoxClosed', cookieBoxClosed

	onBodyClick = (event) =>
		# On each click, check if we've closed the cookie box.
		return unless doesClosedCookieExist()
		@$store.commit 'notification-bar/setCookieBoxClosed', true
		document.body.removeEventListener 'click', onBodyClick

	# If cookie box is open, then add click listener.
	if !cookieBoxClosed
		document.body.addEventListener 'click', onBodyClick

doesClosedCookieExist = -> return document.cookie.indexOf('OptanonAlertBoxClosed=') != -1
