
export default

	props:

		closeable:
			type: Boolean
			default: true

	data: -> open: true

	mounted: ->
		@$scroller.disable @scrollable = @$refs.scrollable
		@$store.commit 'modal/setOpen', true

	beforeDestroy: ->
		@$scroller.enable @scrollable
		@$store.commit 'modal/setOpen', false

	methods:

		# Remove the modal
		close: ->	@open = false if @closeable

		# Remove it after the transition ends
		remove: ->
			@$destroy()
			@$el.remove()
