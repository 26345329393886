
export default

	props:
		headline: String
		subhead: String
		start:
			type: String
			default: 'top top'
		end:
			type: String
			default: '30%'

	mounted: -> @motionSetup()

	methods:

		# Hide elements on scroll
		motionSetup: ->

			# Fade out the title
			@$gsap.to @$refs.headline,
				opacity: 0
				y: -40
				ease: 'none'
				scrollTrigger:
					trigger: @$el
					start: @start
					end: @end

			# Fade out the subhead
			if @$refs.subhead
			then @$gsap.to @$refs.subhead,
				opacity: 0
				y: -35
				delay: 1
				ease: 'none'
				scrollTrigger:
					trigger: @$el
					start: @start
					end: @end

			# # Reduce the height of the hr
			# @$gsap.to @$refs.hr,
			# 	opacity: 0
			# 	y: -20
			# 	ease: 'none'
			# 	scrollTrigger:
			# 		trigger: @$el
			# 		start: @start
			# 		end: @end

