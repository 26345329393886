import { render, staticRenderFns } from "./secondary-links.vue?vue&type=template&id=22034974&scoped=true&lang=pug"
import script from "./secondary-links.vue?vue&type=script&lang=coffee"
export * from "./secondary-links.vue?vue&type=script&lang=coffee"
import style0 from "./secondary-links.vue?vue&type=style&index=0&id=22034974&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22034974",
  null
  
)

export default component.exports