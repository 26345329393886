
import timing from '~/assets/vars/timing.json'
viewedBarsKey = 'viewed-bars'
export default

	# Store the bar to show as data (rather than computed) so it's appearance
	# timing can be controlled
	data: ->
		shownBar: null
		viewedBars: null

	# Set the initial bar a couple seconds after cookies accepted.
	# Note: cookie handling is not currently implemented
	mounted: ->
		@updateViewedBars()
		# # Show immediately if cookieBox is closed
		if @cookieBoxClosed then @$wait timing['notification-bar-delay'], => @show()

	computed:

		# Get the current bar unless it's already been viewed
		currentBar: ->
			return unless @viewedBars?
			return unless bar = @$store.state['notification-bar'].data
			return if bar.id in @viewedBars
			return bar

		cookieBoxClosed: -> @$store.state['notification-bar'].cookieBoxClosed

	watch:

		# Toggle show/hide until after page transition finishes to prevent issues
		# where shifting layout during transition gets messy since adding pinned
		# elements.
		currentBar: ->
			return unless @cookieBoxClosed
			duration = timing['page-transition-duration']
			@$wait duration, => @shownBar = @currentBar

		# When a bar is shown and 'showOnce' is true, store reference in local storage
		# so it won't be shown again. This doesn't mutate viewedBars because we don't
		# want to immediately hide it (via currentBar prop)
		shownBar: ->
			return unless id = @shownBar?.id
			value = JSON.stringify [...(@viewedBars || []), id ]
			if @shownBar.showOnce
				localStorage.setItem viewedBarsKey, value

		# When the route change, update the viewedBars array so we don't reshow
		# an already viewed bar
		'$route.path': -> @updateViewedBars()

		cookieBoxClosed: (newVal, oldVal) ->
			if newVal then @$wait timing['notification-bar-after-cookie-delay'], => @show()

	methods:

		# Update the viewed bars from localstorage
		updateViewedBars: ->
			@viewedBars = JSON.parse localStorage.getItem(viewedBarsKey) || '[]'

		# When closing, set the height back to 0
		onLeave: -> @$store.commit 'notification-bar/setHeight', 0

		# Update scroll calculations after the height changes
		onComplete: -> @$scroller.update()

		show: ->
			@shownBar = @currentBar

