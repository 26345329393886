import { render, staticRenderFns } from "./bar.vue?vue&type=template&id=1fb9f2ee&scoped=true&lang=pug"
import script from "./bar.vue?vue&type=script&lang=coffee"
export * from "./bar.vue?vue&type=script&lang=coffee"
import style0 from "./bar.vue?vue&type=style&index=0&id=1fb9f2ee&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb9f2ee",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,Wysiwyg: require('/opt/build/repo/site/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default})
