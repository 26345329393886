

export default
	model:
		prop: 'open',
		event: 'change'

	props:
		open: Boolean
		small: Boolean

	computed:
		classes: -> 'open' if @open

