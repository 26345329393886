###
Keeps tab focus inside of a modal or popup
###
export default

	data: ->
		# all general focusable elements
		focusableElements: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), .smart-nav-link'
		focusModal: null
		firstElement: null
		lastElement: null
		closeOnEsc: undefined

	methods:

		# Kick off focus
		initFocus: (closeOnEsc) ->
			@closeOnEsc = closeOnEsc

			@$nextTick =>
				# Get reference to wrapper element
				# Modal is wrapped in a transition, so we can't use @$el,
				# use a ref instead.
				@focusModal = @$refs.scrollLockElement || @$el
				@initModalFocus()

		# init the focus modal and set up the elements to listen to
		initModalFocus: ->
			# Get the elements to determine first and last
			focusableContent = @focusModal.querySelectorAll(@focusableElements)
			@firstElement = focusableContent[0]
			@lastElement = focusableContent[focusableContent.length - 1]

			@setListeners()

		# Set the listener for tab press
		setListeners: () ->
			document.addEventListener 'keydown', @tabElements
			@firstElement.focus()

		# Cycle through elements
		tabElements: (e) ->
			tabHit = e.key == 'Tab' or e.keyCode == 9
			escHit = e.key == 'Escape' or e.keyCode == 27
			if escHit then @closeOnEsc?(); return
			if !tabHit then return


			if e.shiftKey
				if document.activeElement == @firstElement
					@lastElement.focus()
					e.preventDefault()

			else if document.activeElement == @lastElement
					@firstElement.focus()
					e.preventDefault()

			else if escHit
				@closeOnEsc?()

		removeFocus: ->
			document.removeEventListener 'keydown', @tabElements

	destroyed: ->
		document.removeEventListener 'keydown', @tabElements
