###
	Init the smooth scroller
###
import Vue from 'vue'
import { extendApp, defer, wait } from '@bkwld/cloak/services/helpers'
import throttle from 'lodash/throttle'
import { ScrollTrigger } from 'gsap/all'
import Lenis from '@studio-freight/lenis'
import {
	disableBodyScroll,
	enableBodyScroll,
	clearAllBodyScrollLocks
} from 'body-scroll-lock'

# Make the scrollY position a global obverserable
export scroll = Vue.observable y: 0

# Make scrollbar instance with public methods for scrolling
class Scroller

	# Init scroll handlers
	init: ->
		# Disable smooth scroll if DISABLE_SMOOTH_SCROLL env var is present,
		# and on touch devices.
		@smooth = not process.env.DISABLE_SMOOTH_SCROLL and
		not ('ontouchstart' of document.documentElement)

		if @smooth then @initLenis()
		else @initVanillaScroll()

	# Use native scroll events, like on a touch device.
	initVanillaScroll: ->
		window.addEventListener 'scroll', -> scroll.y = window.scrollY

	# Init smooth scroller
	initLenis: ->
		return if @lenis

		# Create scroller instance
		@lenis = new Lenis(smoothWheel: true)

		@lenis.on 'scroll', (e) =>
			scroll.y = e.targetScroll

		raf = (time) =>
			@lenis.raf time
			requestAnimationFrame raf

		requestAnimationFrame raf

		# Configure GSAP ScrollTrigger
		@initGSAP()

	# Tell GSAP to use smooth scroller
	# https://codepen.io/ashthornton/pen/PoZRwPW
	initGSAP: ->

		@lenis.on('scroll', ScrollTrigger.update)

	# Recalculate the height
	update: throttle ->
	, 100

	# Expose enable/disable for use when body-scroll-lock-ing. If @smooth,
	# the target isn't currently scrollable. We don't currently have a need for
	# this so I'm not bothering to look into resolving at this time.
	enable: (target) ->
		if target then enableBodyScroll target
		else clearAllBodyScrollLocks()

	disable: (target) ->	disableBodyScroll target

	# Scroll to top
	toTop: ->
		if @smooth then @lenis?.scrollTo 0
		else window.scrollTo
			top: 0
			behavior: 'smooth'

	# Jump to top, like during navigation
	jumpTop: ->
		if @smooth
			clearAllBodyScrollLocks() # In case menu was open
			@lenis.scrollTo 0
		else
			clearAllBodyScrollLocks() # In case menu was open
			window.scrollTo top:0

	scrollTo: (to) ->
		if @lenis and @lenis?.scrollTo then @lenis.scrollTo to
		else window.scrollTo {top: to, behavior: 'smooth'}

# Inject globals
export default ({ app, isDev }, inject) ->

	# When not dev-ing, if we wait for the app to mount, gsap isn't available
	# in time for fast mounting components.
	scroller = new Scroller
	unless isDev then scroller.init()
	else extendApp app, mounted: -> scroller.init()

	# Jump to top when starting a route navigation. The conditionals prevent
	# firing on the initial request.
	app.router.beforeEach (to, from, next) ->
		if process.client && from.name && to.path != from.path
		then scroller.jumpTop()
		next()

	# Update scroller calc when route changes
	app.router.afterEach (to, from) ->
		defer -> scroller.update()

		# Try again after styles should have injected in SPA / dev mode. Also a
		# safety check in prod
		wait 1000, -> scroller.update()

	# Inject instances
	inject 'scroll', scroll
	inject 'scroller', scroller
