
import throttle from 'lodash/throttle'
export default

	props: content: Object

	# Setup height listeners
	mounted: ->
		@updateHeight()
		window.addEventListener 'resize', @updateHeight

		# Wait until the container transitions have finished
		@$wait 700, @motionSetup

	# Cleanup
	destroyed: ->
		window.removeEventListener 'resize', @updateHeight
		@cleanupMounted?()

	computed:

		# Add root styles
		styles: -> background: @content.backgroundColor

	methods:

		# Setup scrolltrigger animations
		motionSetup: -> @cleanupMounted = @$setupReducedMotion =>

			# Pin the bar and fade it out
			@pinning = @$gsap.to @$refs.pinned,
				opacity: 0.4
				scrollTrigger:
					trigger: @$el
					start: 'top top'
					pin: @$refs.pinned
					pinSpacing: false

			# Scale the pill
			@$gsap.to @$refs.pill,
				scale: 0.95
				scrollTrigger:
					trigger: @$el
					start: 'top top'

		# Update the vuex height
		updateHeight: throttle ->
			@$store.commit 'notification-bar/setHeight', @$el?.offsetHeight
		, 100

