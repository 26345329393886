
import HeaderMixin from './header-mixin'
import dimensions from '~/assets/vars/dimensions'

topMargin = "32px"

export default

	mixins: [ HeaderMixin ]

	data: ->
		height: parseInt(dimensions['header-h']) + parseInt(topMargin)
		scrollPosition: null

	computed:
		maskStyle: -> if @scrollPosition then "transform: translateY(#{-@scrollPosition}px)"
		isAssetMarquee: -> @$store.state.layout.isAssetMarquee
		# Offset we apply to the header when it's not detached, and we're at the top of the page
		assetTopOffset:  -> parseInt dimensions['desktop-header-offset-when-on-asset']
		headerOffset: ->
			# If open, remove all offsets so there's no unfiltered background above the menu
			return 0 if @open
			if @isAssetMarquee then @notificationBarHeight + @assetTopOffset else @notificationBarHeight
		offScreenHeight: ->
			offset = if @isAssetMarquee then @notificationBarHeight + @assetTopOffset else @notificationBarHeight
			@height + offset

	methods:
		scroll: (scrollValue) ->
			@scrollPosition = scrollValue

