import { render, staticRenderFns } from "./desktop.vue?vue&type=template&id=f238bec2&scoped=true&lang=pug"
import script from "./desktop.vue?vue&type=script&lang=coffee"
export * from "./desktop.vue?vue&type=script&lang=coffee"
import style0 from "./desktop.vue?vue&type=style&index=0&id=f238bec2&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f238bec2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderHamburguer: require('/opt/build/repo/site/components/layout/header/hamburguer.vue').default,NuroLogo: require('/opt/build/repo/site/components/globals/nuro-logo.vue').default,LayoutHeaderMenu: require('/opt/build/repo/site/components/layout/header/menu.vue').default})
