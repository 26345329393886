###
Change the intersectionObserver to #scroller when SmoothScrolling is enabled
###
import CraftVisual from '@bkwld/cloak/components/craft-visual'
lazyLoadRoot = undefined # If undefined, window will be used
export default
	functional: true

	# Passthrough props
	props: { ...CraftVisual.props }

	# Render CraftVisual
	render: (create, { props, data, children }) ->

		# If smoooth scroll is enabled, cache a reference to the scroller el
		# so it isn't re-looked up on every Visual instance
		if process.client and window.$nuxt.$scroller.smooth
		then lazyLoadRoot = document.getElementById 'scroller'

		# Pass in the smooth scroll root
		create CraftVisual, {
			...data
			props: {
				...props
				intersectionOptions:
					rootMargin: '50% 0% 50% 0%'
					root: lazyLoadRoot
			}
		}, children
