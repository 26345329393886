###
For relating tower concerns to the layout
###
export state = ->
	lightMarquee: false
	darkLastBlock: false


export mutations =

	# Store whether the current marquee has a light background
	setLightMarquee: (state, bool) -> state.lightMarquee = bool

	# Store whether the block right above the footer has a light background
	setDarkLastBlock: (state, bool) -> state.darkLastBlock = bool
