###
Inject global services and helpers into Nuxt
###
import * as helpers from '~/services/helpers'
export default ({ app, error, query }, inject) ->

	# Inject Helpers
	inject name, func for name, func of helpers


