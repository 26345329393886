import { render, staticRenderFns } from "./standard-marquee.vue?vue&type=template&id=4ac23ffa&scoped=true&lang=pug"
import script from "./standard-marquee.vue?vue&type=script&lang=coffee"
export * from "./standard-marquee.vue?vue&type=script&lang=coffee"
import style0 from "./standard-marquee.vue?vue&type=style&index=0&id=4ac23ffa&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac23ffa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CraftVisual: require('/opt/build/repo/site/components/globals/craft-visual.coffee').default,MarqueeHeadline: require('/opt/build/repo/site/components/globals/marquee-headline.vue').default,ExpandingArc: require('/opt/build/repo/site/components/globals/expanding-arc.vue').default})
