// Cross browser compatible full screen handler
/** Cross browser compatible full screen handle
 *
 * @example
 *
 * ```js
 * const fullScreenHandler = new FullScreenHandler();
 * fullScreenHandler.toggleFullScreen(element);
 *
 * fullScreenHandler.addFullScreenChangeListener(() => {
 * if (fullScreenHandler.getFullScreenElement()) {
 * 	console.log('full screen activated')
 * } else {
 * console.log('full screen deactivated')
 * }})
 * ```
 */
export default class FullScreenHandler {
	constructor() {
			this.fullScreenChangeEvent = this.getFullScreenChangeEvent();
	}

	// Check if Full Screen API is supported
	checkFullScreenEnabled() {
			if (!process.server) return (
					document.fullscreenEnabled ||
					document.webkitFullscreenEnabled ||
					document.mozFullScreenEnabled ||
					document.msFullscreenEnabled
			);
	}

	// Get the Full Screen Element
	getFullScreenElement() {
			if (!process.server) return (
					document.fullscreenElement ||
					document.webkitFullscreenElement ||
					document.mozFullScreenElement ||
					document.msFullscreenElement
			);
	}

	// Get the Full Screen Change Event
	getFullScreenChangeEvent() {
			return (
					'fullscreenchange' ||
					'webkitfullscreenchange' ||
					'mozfullscreenchange' ||
					'MSFullscreenChange'
			);
	}

	// Toggle Full Screen for an element
	toggleFullScreen(element) {
			if (this.checkFullScreenEnabled()) {
					if (!this.getFullScreenElement()) {
							if (element.requestFullscreen) {
									element.requestFullscreen();
							} else if (element.webkitRequestFullscreen) {
									element.webkitRequestFullscreen();
							} else if (element.mozRequestFullScreen) {
									element.mozRequestFullScreen();
							} else if (element.msRequestFullscreen) {
									element.msRequestFullscreen();
							}
					} else {
							if (document.exitFullscreen) {
									document.exitFullscreen();
							} else if (document.webkitExitFullscreen) {
									document.webkitExitFullscreen();
							} else if (document.mozCancelFullScreen) {
									document.mozCancelFullScreen();
							} else if (document.msExitFullscreen) {
									document.msExitFullscreen();
							}
					}
			} else if (element.webkitEnterFullScreen) {
				element.webkitEnterFullScreen();
			}
	}

	// Add Event Listener for Full Screen Change
	addFullScreenChangeListener(callback) {
			document.addEventListener(this.fullScreenChangeEvent, callback);
	}

	// Remove Event Listener for Full Screen Change
	removeFullScreenChangeListener(callback) {
			document.removeEventListener(this.fullScreenChangeEvent, callback);
	}
}
