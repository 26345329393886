
import { padNum, kebabCase } from '~/services/helpers'
import VideoCard from '~/components/globals/video-card'
export default
	props: block: Object

	data: ->
		active: 0
		paginationRefresher: 0
		autoplayInterval: null

	components: { VideoCard }

	computed:
		classes: -> [
			@activeSlide.horizontalAlignment || "left"
			kebabCase @activeSlide.verticalAlignment || "bottom", 'vertical-alignment'
		]

		textColorStyle: -> color: @activeSlide?.textColor || "black"

		activeSlide: -> @block.slides[@active]

		imageClasses: -> [
			"overlay-intensity-#{@activeSlide.overlayIntensity || 'low'}" if @activeSlide.overlayIntensity != "no-overlay"
			"overlay-type-#{@activeSlide.overlayType || 'solid'}" if @activeSlide.overlayIntensity != "no-overlay"
		]

		imageDesktop: -> if @activeSlide?.imageDesktop.length then @activeSlide?.imageDesktop else @activeSlide?.imageMobile
		imageMobile: -> if @activeSlide?.imageMobile.length then @activeSlide.imageMobile else @activeSlide.imageDesktop

		videoDesktop: -> if @activeSlide?.videoDesktop?.length then @activeSlide.videoDesktop else @activeSlide.videoMobile
		videoMobile: -> if @activeSlide?.videoMobile?.length then @activeSlide.videoMobile else @activeSlide.videoDesktop

		headline: -> @activeSlide?.headline
		kicker: -> @activeSlide?.kicker
		copy: -> @activeSlide?.copy
		unorphan: -> @activeSlide?.unorphan
		balanceText: -> @activeSlide?.balanceText
		copy: -> @activeSlide?.copy
		video: -> @activeSlide?.libraryVideo
		videoCardButtons: -> @activeSlide?.videoCardButtons
		videoTitle: -> @activeSlide?.videoCardTitle
		videoSubtitle: -> @activeSlide?.videoCardSubtitle
		videoDescription: -> @activeSlide?.videoCardDescription
		videoButtons: -> @activeSlide?.videoCardButtons

		buttons: -> @activeSlide?.buttons
		pageOffset: ->
			"--margin-left-desktop": "#{@active*70}px"
			"--margin-left-mobile": "#{@active*40}px"
		activePage: -> padNum @active + 1

		autoplayDelay: -> ( parseInt(@block.autoplayDelay) || 3) * 1000

	watch: active: -> @paginationRefresher += 1

	mounted: ->
		window.addEventListener 'resize', @getPaginationDimension
		@autoplayInterval = setInterval @nextSlide, @autoplayDelay
		@setupMotion()

	beforeDestroy: ->	window.removeEventListener 'resize', @getPaginationDimension

	methods:
		goTo: (index) ->
			clearInterval @autoplayInterval
			@active = index
			@autoplayInterval = setInterval @nextSlide, @autoplayDelay

		setupMotion: -> @cleanupMotionAllSizes = @$setupReducedMotion @setupMotionAllSizes

		setupMotionAllSizes: ->
			@timeline1 = @$gsap.timeline()
				.pause()
				.set @$refs.bkg, {scale: 1.1}
				.to @$refs.bkg, 10, {scale: 1, ease: 'linear'}, 0
				.addLabel 'end', 10

			trigger1Position = if @blockIndex == 0 then { start: "top top", end: "center top" } else { start: "top 40%", end: "top 0%"}

			@trigger1 = @$ScrollTrigger.create {
				trigger: @$refs.pin
				...trigger1Position
				animation: @timeline1
			}

			@$ScrollTrigger.refresh(true)


		getImageDesktop: (slide) -> if slide?.imageDesktop.length then slide?.imageDesktop else slide?.imageMobile
		getImageMobile: (slide) -> if slide?.imageMobile.length then slide?.imageMobile else slide?.imageDesktop

		getVideoDesktop: (slide) -> if slide?.videoDesktop?.length then slide?.videoDesktop else slide?.videoMobile
		getVideoMobile: (slide) -> if slide?.videoMobile?.length then slide?.videoMobile else slide?.videoDesktop

		play: (youtubeVideoUrl) -> @$store.commit 'videoModal/play', youtubeVideoUrl

		nextSlide: -> @active = (@active+1) % @block.slides.length

