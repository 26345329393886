
import Modal from '~/components/globals/modal/variation'

export default

	components: {
		Modal
	}

	computed:
		# Whether the modal should be open
		open: -> @$store.state.imageModal.open

		imageDesktop: -> @$store.state.imageModal.desktopImage?[0]
		imageMobile: -> @$store.state.imageModal.mobileImage?[0]

	methods:
		#  Close modal
		close: ->
			@$store.commit 'imageModal/setOpen', false

