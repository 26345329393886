
import keepFocus from '~/mixins/keep-focus'
export default
	mixins: [keepFocus]

	methods:
		share: -> @$emit 'share'
		playbackSpeed: -> @$emit 'playback-speed'
		pictureInPicture: -> @$emit 'picture-in-picture'

	mounted: ->
		@$nextTick => @initFocus(() => @$emit 'close')

