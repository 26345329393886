
export default
	props:
		progress: Number
		duration: Number

	data: ->
		isScrubbing: false

	computed:
		progressStyle: -> {width: "#{( @progress || 0 )*100}%"}
		videoDuration: -> @duration || 0

	methods:
		startScrubbing: ->
			@isScrubbing = true
			@$emit 'startscrub'

			window.addEventListener 'mousemove', @handleScrubbing
			window.addEventListener 'mouseup', @endScrubbing

		handleScrubbing: (event) ->
			if @isScrubbing
				scrubbingWidth = (event.offsetX / event.target.clientWidth)
				@$emit 'progressupdate', scrubbingWidth

		endScrubbing: (event) ->
			if @isScrubbing
				progress = (event.offsetX / event.target.clientWidth)
				@$emit 'progressupdate', progress
				@$emit 'endscrub'
				@isScrubbing = false
				window.removeEventListener 'mousemove', @handleScrubbing
				window.removeEventListener 'mouseup', @endScrubbing

