
export default
	props:
		value: Number | String
		hasAudio: Boolean


	# Sync external v-model
	data: ->
		state: @value
		show: false
		sliderFocus: false

	watch:
		value: -> @state = @value
		state: -> @$emit 'input', @state

	computed:
		# The icon for the volume  button
		volumeIcon: ->
			return "icon-speaker-mute" if !@hasAudio
			if @state != 0 then 'icon-speaker' else 'icon-speaker-mute'

	methods:
		onMouseEnter: ->
			return unless @hasAudio
			@show = true

		onMouseLeave: ->
			return unless @hasAudio
			@show = false

		# Toggle volume on/off
		toggleVolume: ->
			if @state > 0 then @state = 0
			else @state = 1

