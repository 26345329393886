###
Shared header code
###
import Vue from 'vue'
import DetachableHeader from 'vue-detachable-header'
import 'vue-detachable-header/index.css'
import { isDark } from '@bkwld/light-or-dark'

# Hack detachable header to show the background early, while the smooth
# scroller is still tweening to 0
_showBackground = DetachableHeader.computed.showBackground
DetachableHeader.computed.showBackground = ->
	return true if @forceBackground
	if @isDetached and @scrollY <= @offset + 10 then false
	else _showBackground.apply this

# Share open state between headers without using Vuex or moving the var to
# the layout or something like that
export sharedState = Vue.observable open: false

export default

	components: { DetachableHeader }

	data: -> open: false

	# Sync changes to shares state back to this header instance
	created: ->
		@unwatchSharedState = @$watch(
			-> sharedState.open,
			=> @open = sharedState.open
		)
	destroyed: -> @unwatchSharedState()

	computed:

		topBackgroundColor: -> @$store.state.layout.headerBackgroundColor

		isTopBackgroundDark: -> if @topBackgroundColor then isDark(@topBackgroundColor || 'white') else false

		isAssetMarquee: -> @$store.state.layout.isAssetMarquee

		# Root classes
		classes: ->
			'menu-open': @open
			'light-bkgd': @$store.state.tower.lightMarquee
			'is-bg-dark': @isTopBackgroundDark
			'is-bg-asset': @isAssetMarquee

		# Get the notification bar height
		notificationBarHeight: -> @$store.state['notification-bar'].height || 0

	watch:

		# When the route path changes, close
		'$route.path': -> @open = false

		# Sync open to shared state
		open: -> sharedState.open = @open
