
import copyToClipboard from '~/services/copy-to-clipboard'
import keepFocus from '~/mixins/keep-focus'
export default
	props:
		title: String

	data: ->
		hasBeenCopied: false

	mixins: [keepFocus]

	computed:
		# Get current path
		currentURL: ->
			if process.server then return process.env.URL + @$route.fullPath
			else return window.location.href

		# Links to share
		shareLinks: ->
			twitter:
				href: "https://twitter.com/intent/tweet?text=Check+this+video+from+Nuro:+&url=#{@currentURL}&via=nuro"

			facebook:
				href: "https://www.facebook.com/sharer/sharer.php?u=#{ @currentURL }"

			linkedin:
				href: "https://www.linkedin.com/sharing/share-offsite/?url=#{@currentURL}"

	methods:
		# Share to a social platform
		shareTo: (shareTarget) ->
			return window.open @shareLinks[shareTarget].href, "Share #{@title}}", 'resizable, height=600, width=700'

		# Alert the parent that you should be closed
		close: -> @$emit 'close'

		# Copy current url to clipboard
		copy: ->
			navigator.clipboard.writeText(@currentURL);
			@hasBeenCopied = true

	mounted:  -> @$nextTick =>
		@initFocus(() => @$emit 'close')

